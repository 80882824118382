import React from 'react';
import Link from 'gatsby-link';
import {
  Card,
  Icon,
} from 'semantic-ui-react';

const Home = () => (
  <Card.Group>
    <Card id="about">
      <Card.Content>
        <Card.Header>About</Card.Header>
        <Card.Meta>What is My Uncle's 228?</Card.Meta>
        <Card.Description>
          <p>
            This site is devoted to documenting the history and continuing story of my uncle's 1989 Maserati 228,
            which I am now the proud owner of.
          </p>
        </Card.Description>
      </Card.Content>
    </Card>
  </Card.Group>
);

export default Home;
